import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlatButton, TextField, ProgressIndicator, IconText, Alert } from '@travel/ui';
import { Attention } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { pushLocation } from 'store/__router/actions';
import { useFormControl } from 'hooks/useFormControl';
import { processOneTimeKey, validateOneTimeKey } from 'store/login/actions';
import { getLogin } from 'store/login/selectors';
import {
  PASSWORD_REGEXES,
  ONE_CAPITAL,
  ONE_LOWERCASE,
  ONE_NUMBER,
  ONE_SYMBOL,
  NOT_CONSECUTIVE_NUM,
  NOT_THREETIMES_ALPHA,
  NOT_INVALID_WORDS,
} from 'constants/validations';
import { ChangePasswordWithOneTimeKey, NewPasswordData } from 'Login-Types';
import { resetPasswordSteps } from '../ForgotPassword';
import ErrorMessage from 'pages/Login/errorMessage';
import NewPasswordForm from '../NewPasswordForm';
import styles from './oneTimeKey.module.scss';
import { labels, labelIds } from './labels';
import { MAP_RESPONSE_TO_CODE } from 'constants/login';

function OneTimeKey() {
  const dispatch = useDispatch();
  const loginState = useSelector(getLogin);

  const oneTimeKeyControl = useFormControl('');

  const [errorType, setErrorType] = useState('');
  const [nextStep, setNextStep] = useState(2);
  const [isSuccessResetPassword, setIsSuccessResetPassword] = useState(false);

  const submitKey = useCallback(async () => {
    if (!loginState.isFetching) {
      if (!!oneTimeKeyControl.currentData) {
        setErrorType('');
        const response = await dispatch(validateOneTimeKey(oneTimeKeyControl.currentData));
        if (typeof response === 'boolean' && response) {
          setNextStep(3);
        } else if (response.message) {
          setErrorType(response.message);
        }
      }
    }
  }, [loginState.isFetching, oneTimeKeyControl.currentData, dispatch]);

  const goBackToForgotPassword = () => dispatch(pushLocation(`/login/forgotPassword`));

  const onResetPassword = useCallback(
    async (passwordData: NewPasswordData) => {
      if (loginState.isFetching) return;

      const newPassword = passwordData?.newPassword || '';
      const isValidPassword = PASSWORD_REGEXES.reduce(
        (acc, curRegEx) => acc && curRegEx.test(newPassword),
        true,
      );

      if (!ONE_CAPITAL.test(newPassword)) {
        setErrorType('no_capital');
        return;
      }
      if (!ONE_LOWERCASE.test(newPassword)) {
        setErrorType('no_lowercase');
        return;
      }

      if (!ONE_NUMBER.test(newPassword)) {
        setErrorType('no_number');
        return;
      }
      if (!ONE_SYMBOL.test(newPassword)) {
        setErrorType('no_symbol');
        return;
      }
      if (!NOT_CONSECUTIVE_NUM.test(newPassword)) {
        setErrorType('consecutive_number');
        return;
      }
      if (!NOT_THREETIMES_ALPHA.test(newPassword)) {
        setErrorType('three_times_alpha');
        return;
      }

      if (newPassword.length < 8) {
        setErrorType('too_short');
        return;
      }

      if (newPassword.length > 64) {
        setErrorType('too_long');
        return;
      }

      if (!NOT_INVALID_WORDS.test(newPassword)) {
        setErrorType('invalid_word');
        return;
      }

      if (!isValidPassword) {
        setErrorType('invalid_password');
        return;
      }
      if (newPassword !== passwordData.passwordConfirm) {
        setErrorType('passwordMismatch');
        return;
      }

      const data: ChangePasswordWithOneTimeKey = {
        oneTimeKey: oneTimeKeyControl.currentData,
        newPassword: passwordData.newPassword,
      };
      const response = await dispatch(processOneTimeKey(data));
      if (typeof response === 'boolean' && response) {
        setIsSuccessResetPassword(true);
        setErrorType('');
      } else if (response.code && response.code.includes('newPW.usedBefore')) {
        setErrorType('usedPassword');
      } else if (response.code && response.code.includes('error.invalidPw')) {
        setErrorType(MAP_RESPONSE_TO_CODE[response.code]);
      } else {
        setErrorType('unexpected_error');
      }
    },
    [dispatch, loginState.isFetching, oneTimeKeyControl.currentData],
  );

  const resetErrorType = () => setErrorType('');

  return (
    <div
      className={styles.wrapper}
      data-locator-id="OneTimeKey-f7a2b237-3b09-4127-85e6-983eb80f1a7f"
      data-testid="oneTimeKey-wrapper"
    >
      <div className={styles.headerContainer}>
        <p className={styles.headerTitle}>{labels.oneTimeKeyHeader}</p>
        <ProgressIndicator
          className={styles.progressIndicator}
          label={resetPasswordSteps}
          activeStep={nextStep}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentTitle}>
          {nextStep === 2 ? (
            <>
              <p>{labels.oneTimeKeyInstruction}</p>
              <Alert
                type={'info'}
                isClosable={false}
                title={labels.attentionTitle}
                description={labels.attentionDescription}
                descriptionClassName={styles.alartDescription}
              />
            </>
          ) : (
            <p>{labels.resetPasswordInstruction}</p>
          )}
        </div>
        {errorType !== '' && (
          <IconText
            className={styles.errorMessage}
            icon={<Attention size={24} />}
            text={<ErrorMessage errorType={errorType} />}
          />
        )}
        {nextStep === 2 ? (
          <>
            <TextField
              className={styles.fieldContainer}
              onChange={oneTimeKeyControl.onChange}
              value={oneTimeKeyControl.currentData}
              onClear={oneTimeKeyControl.onClear}
              label={<Translate id={labelIds.oneTimeKeyLabel} className={styles.required} />}
              data-testid="oneTimeKey-inputField"
            />
            <div className={styles.eventBtnContainer}>
              <FlatButton
                className={styles.cancelBtn}
                classType="secondary"
                onClick={goBackToForgotPassword}
                data-testid="secondary-button"
              >
                {labels.cancelButton}
              </FlatButton>
              <FlatButton
                onClick={submitKey}
                isDisabled={!oneTimeKeyControl.currentData}
                isLoading={loginState.isFetching}
                data-testid="primary-button"
              >
                {labels.submitButton}
              </FlatButton>
            </div>
          </>
        ) : (
          <NewPasswordForm
            onResetPassword={onResetPassword}
            isFetching={loginState.isFetching}
            isSuccessPasswordChanged={isSuccessResetPassword}
            errorType={errorType}
            resetErrorType={resetErrorType}
          />
        )}
      </div>
    </div>
  );
}

export default OneTimeKey;

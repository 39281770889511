import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FlatButton, TextField, PopupMessage } from '@travel/ui';
import { EyeOpen, EyeClose } from '@travel/icons/ui';
import { ReturnData, useFormControl } from 'hooks/useFormControl';
import { pushLocation } from 'store/__router/actions';
import { NewPasswordData } from 'Login-Types';
import styles from './newPasswordForm.module.scss';
import { Translate, useTranslate } from '@travel/translation';

type Props = {
  onResetPassword: (passwordData: NewPasswordData) => {};
  isFetching: boolean;
  isSuccessPasswordChanged: boolean;
  errorType: string;
  resetErrorType: () => void;
};

function NewPasswordForm(props: Props) {
  const {
    onResetPassword,
    isFetching,
    isSuccessPasswordChanged,
    errorType,
    resetErrorType,
  } = props;
  const dispatch = useDispatch();
  const newPasswordControl = useFormControl('');
  const passwordConfirmControl = useFormControl('');
  const [pwdShow, setPwdShow] = useState<boolean>(false);
  const [pwdConfirmShow, setPwdConfirmShow] = useState<boolean>(false);

  const fieldOnChange = (fieldControl: ReturnData) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    fieldControl.onChange(event);
    resetErrorType();
  };

  const togglePwdIcon = () => setPwdShow(prev => !prev);

  const togglePwdConfirmIcon = () => setPwdConfirmShow(prev => !prev);

  const onClick = useCallback(() => {
    const data: NewPasswordData = {
      newPassword: newPasswordControl.currentData,
      passwordConfirm: passwordConfirmControl.currentData,
    };
    onResetPassword(data);
  }, [newPasswordControl.currentData, onResetPassword, passwordConfirmControl.currentData]);

  const successPasswordChanged = () => dispatch(pushLocation(`/login/passwordChanged`));

  const newPasswordPlaceholder = useTranslate({
    id: 'loginlogout.forget_password_reset_password.form.new_password_example_text',
  });

  const passwordConfirmPlaceholder = useTranslate({
    id: 'loginlogout.forget_password_reset_password.form.password_confirmation_example_text',
  });

  return (
    <>
      {/** should unify with <PasswordSection> */}
      <TextField
        className={styles.fieldContainer}
        onChange={fieldOnChange(newPasswordControl)}
        value={newPasswordControl.currentData}
        label={
          <Translate
            id={'loginlogout.forget_password_reset_password.form.new_password'}
            className={styles.required}
          />
        }
        inputProps={{
          placeholder: newPasswordPlaceholder,
          type: pwdShow ? 'text' : 'password',
        }}
        hasError={errorType === 'invalid_password'}
        trailingIcon={
          <button className={styles.toggleBtn} type="button" onClick={togglePwdIcon}>
            {pwdShow ? <EyeOpen size={24} className={styles.eyeOpen} /> : <EyeClose size={24} />}
          </button>
        }
        data-testid="newPassword-inputField"
      />
      <TextField
        className={styles.fieldContainer}
        onChange={fieldOnChange(passwordConfirmControl)}
        value={passwordConfirmControl.currentData}
        label={
          <Translate
            id={'loginlogout.forget_password_reset_password.form.password_confirmation'}
            className={styles.required}
          />
        }
        inputProps={{
          placeholder: passwordConfirmPlaceholder,
          type: pwdConfirmShow ? 'text' : 'password',
        }}
        hasError={errorType === 'password_mismatch'}
        trailingIcon={
          <button className={styles.toggleBtn} type="button" onClick={togglePwdConfirmIcon}>
            {pwdConfirmShow ? (
              <EyeOpen size={24} className={styles.eyeOpen} />
            ) : (
              <EyeClose size={24} />
            )}
          </button>
        }
        data-testid="confirmPassword-inputField"
      />
      <FlatButton
        className={styles.resetPasswordBtn}
        onClick={onClick}
        isDisabled={!newPasswordControl.currentData || !passwordConfirmControl.currentData}
        isLoading={isFetching}
        data-testid="primary-button"
      >
        <Translate id={'loginlogout.forget_password_reset_password.button.reset_password'} />
      </FlatButton>
      <PopupMessage
        isOpen={isSuccessPasswordChanged}
        isSuccess
        onCloseDialog={successPasswordChanged}
        customSubTitle={
          <p className={styles.successDialog}>
            <Translate id={'loginlogout.popup_reset_password.message'} />
          </p>
        }
        buttonText={<Translate id={'loginlogout.popup_reset_password.button.ok'} />}
        popUpLabel={{
          successTitle: <Translate id={'loginlogout.popup_reset_password.message.main'} />,
        }}
      />
    </>
  );
}

export default NewPasswordForm;

import { useState } from 'react';

export type ReturnData = {
  currentData: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
};

export const useFormControl = (initialData?: string): ReturnData => {
  const [currentData, setCurrentData] = useState(initialData || '');
  const onClear = () => setCurrentData('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setCurrentData(event.target.value);
  };

  return { currentData, onChange, onClear };
};

import React from 'react';
import { Translate } from '@travel/translation';
import FAQLink from 'components/FAQLink';

export const labels = {
  pwdError: {
    mismatch: (
      <Translate id="loginlogout.forget_password_reset_password.system_message" exist={true} />
    ),
    noCapital: <Translate id="Extranet_Error.Enter.Password_Capital" />,
    noLowercase: <Translate id="Extranet_Error.Enter.Password_Lowercase" />,
    noNumber: <Translate id="Extranet_Error.Enter.Password_One_Number" />,
    noSymbol: <Translate id="Extranet_Error.Enter.Password_One_Symbol" />,
    consecutiveNumber: <Translate id="Extranet_Error.Enter.Password_Consecutive" />,
    threeTimesAlpha: <Translate id="Extranet_Error.Enter.Password_Three_Alphabet" />,
    invalidChar: <Translate id="Extranet_Error.Enter.Password_Unsupported_Character" />,
    invalidWord: <Translate id="Extranet_Error.Enter.Password_illegal_word" />,
    containUsername: <Translate id="Extranet_Error.Enter.Password_illegal_username" />,
    tooLong: <Translate id="Extranet_Error.Enter.Password_Too_Long" />,
    tooShort: <Translate id="Extranet_Error.Enter.Password_Too_Short" />,
  },
  keyError: {
    invalidKey: (
      <Translate
        id="loginlogout.login_panel.system_message"
        condition={() => `status == invalid_key`}
        data={{
          help_link: <FAQLink />,
        }}
      />
    ),
  },
} as const;

export const labelIds = {} as const;

import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlatButton, TextField, IconText, ProgressIndicator } from '@travel/ui';
import { Attention, EmailConfirm } from '@travel/icons/ui';
import { pushLocation } from 'store/__router/actions';
import { useFormControl, ReturnData } from 'hooks/useFormControl';
import { processForgotPassword } from 'store/login/actions';
import { getLogin } from 'store/login/selectors';
import { EMAIL_REGEXP } from 'constants/validations';
import ErrorMessage from 'pages/Login/errorMessage';
import styles from './forgotPassword.module.scss';
import { Translate, useTranslate } from '@travel/translation';
import FAQLink from 'components/FAQLink';

export const resetPasswordSteps = [
  <Translate id={'loginlogout.forget_password_information.status_bar.information'} />,
  <Translate id={'loginlogout.forget_password_information.status_bar.one_time_key'} />,
  <Translate id={'loginlogout.forget_password_information.status_bar.reset_password'} />,
];

export const initialFieldValidation = {
  providerRelatedId: false,
  loginId: false,
  emailAdd: false,
};

type Props = {
  credentials?: {
    providerUuid: string;
    userId: string;
    email: string;
  };
};

function ForgotPassword(props: Props) {
  const { credentials } = props;
  const { providerUuid = '', userId = '', email = '' } = credentials || {};

  const dispatch = useDispatch();
  const loginState = useSelector(getLogin);

  const [nextStep, setNextStep] = useState(1);
  const providerIdControl = useFormControl(providerUuid);
  const loginIdControl = useFormControl(userId);
  const emailAddControl = useFormControl(email);

  const [hasFieldError, setHasFieldError] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [fieldErrorValidation, setFieldErrorValidation] = useState(initialFieldValidation);

  const onSubmit = useCallback(async () => {
    if (!loginState.isFetching) {
      const isValidEmail = EMAIL_REGEXP.test(emailAddControl.currentData);
      setFieldErrorValidation({
        providerRelatedId: !providerIdControl.currentData,
        loginId: !loginIdControl.currentData,
        emailAdd: !isValidEmail && !emailAddControl.currentData,
      });

      const data = {
        providerRelatedId: providerIdControl.currentData,
        loginId: loginIdControl.currentData,
        email: emailAddControl.currentData,
      };

      if (
        !(Object.keys(data) as Array<keyof typeof data>).find(item => !data[item]) &&
        isValidEmail
      ) {
        const response = await dispatch(processForgotPassword(data));
        if (response.status === 201) {
          setNextStep(2); // meaningless process
          dispatch(pushLocation(`/login/oneTimeKey`));
        } else {
          setHasFieldError(true);
          if (response.message && response.message.includes('common.notMatch'))
            setErrorType('wrongPassword');
          else setErrorType('problem');
        }
      } else {
        setHasFieldError(true);
        setErrorType(isValidEmail ? '' : 'invalid_email');
      }
    }
  }, [
    loginState.isFetching,
    emailAddControl.currentData,
    providerIdControl.currentData,
    loginIdControl.currentData,
    dispatch,
  ]);

  const fieldOnChange = (fieldControl: ReturnData) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    fieldControl.onChange(event);
    setFieldErrorValidation(initialFieldValidation);
    setHasFieldError(false);
  };

  const goBack = () => dispatch(pushLocation(`/login`));

  const providerIdPlaceholder = useTranslate({
    id: 'loginlogout.forget_password_information.form.provider_id_example_text',
  });
  const loginIdPlaceholder = useTranslate({
    id: 'loginlogout.forget_password_information.form.login_id_example_text',
  });
  const passwordPlaceHolder = useTranslate({
    id: 'loginlogout.forget_password_information.form.email_address_example_text',
  });

  return (
    <div
      className={styles.wrapper}
      data-locator-id="ForgotPassword-8f744d4d-8852-4c63-a07b-d3ef69c5af0d"
    >
      <div className={styles.headerContainer}>
        <p className={styles.headerTitle}>
          <Translate id={'loginlogout.forget_password_information.header.title'} />
        </p>
        <ProgressIndicator
          className={styles.progressIndicator}
          label={resetPasswordSteps}
          activeStep={nextStep}
        />
      </div>
      <div className={styles.contentContainer} data-testid="forgotPasswordForm-wrapper">
        {nextStep === 1 ? (
          <>
            <div className={styles.contentTitle}>
              <p>
                <Translate
                  id={'loginlogout.forget_password_information.Instruction_text'}
                  data={{ password_faq_link: <FAQLink /> }}
                />
              </p>
            </div>
            {hasFieldError && (
              <IconText
                className={styles.errorMessage}
                icon={<Attention size={24} />}
                text={<ErrorMessage errorType={errorType} />}
              />
            )}
            <TextField
              className={styles.fieldContainer}
              onChange={fieldOnChange(providerIdControl)}
              value={providerIdControl.currentData}
              onClear={providerIdControl.onClear}
              label={
                <Translate
                  id={'loginlogout.forget_password_information.form.provider_id'}
                  className={styles.required}
                />
              }
              inputProps={{
                placeholder: providerIdPlaceholder,
              }}
              hasError={fieldErrorValidation.providerRelatedId}
              data-testid="providerID-inputField"
            />
            <TextField
              className={styles.fieldContainer}
              onChange={fieldOnChange(loginIdControl)}
              value={loginIdControl.currentData}
              onClear={loginIdControl.onClear}
              label={
                <Translate
                  id={'loginlogout.forget_password_information.form.login_id'}
                  className={styles.required}
                />
              }
              inputProps={{
                placeholder: loginIdPlaceholder,
              }}
              hasError={fieldErrorValidation.loginId}
              data-testid="loginID-inputField"
            />
            <TextField
              className={styles.fieldContainer}
              onChange={fieldOnChange(emailAddControl)}
              value={emailAddControl.currentData}
              onClear={emailAddControl.onClear}
              label={
                <Translate
                  id={'loginlogout.forget_password_information.form.email_address'}
                  className={styles.required}
                />
              }
              inputProps={{
                placeholder: passwordPlaceHolder,
              }}
              hasError={fieldErrorValidation.emailAdd || errorType === 'invalid_email'}
              data-testid="email-inputField"
            />
            <div className={styles.eventBtnContainer}>
              <FlatButton
                className={styles.cancelBtn}
                classType="secondary"
                onClick={goBack}
                data-testid="secondary-button"
              >
                <Translate id={'loginlogout.forget_password_information.button.Cancel'} />
              </FlatButton>
              <FlatButton
                onClick={onSubmit}
                isDisabled={
                  !providerIdControl.currentData ||
                  !loginIdControl.currentData ||
                  !emailAddControl.currentData
                }
                isLoading={loginState.isFetching}
                data-testid="primary-button"
              >
                <Translate id={'loginlogout.forget_password_information.button.Submit'} />
              </FlatButton>
            </div>
          </>
        ) : (
          <>
            <div className={styles.contentTitle}>
              <p>
                <Translate id={'loginlogout.forget_password_email_confirmation.Instruction_text'} />
              </p>
            </div>
            <EmailConfirm />
          </>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;

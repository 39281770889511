import React, { useCallback, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import env from '@travel/env';
import { Attention, EyeClose, EyeOpen, Info } from '@travel/icons/ui';
import { useTranslate, Translate } from '@travel/translation';
import { Checkbox, FlatButton, IconText, IconTextLink, TextField } from '@travel/ui';
import { cx } from '@travel/utils';

import { pushLocation } from 'store/__router/actions';
import { setIsShowBrowserPrompt } from 'store/accessControl/actions';
import { jumpToV1TopFromDialog, postLogin, resetPasswordPreData } from 'store/login/actions';

import { useFormControl, ReturnData } from 'hooks/useFormControl';
import ErrorMessage from 'pages/Login/errorMessage';
import { getLocation } from 'store/__router/selectors';
import { getLoggedInUserScope } from 'store/accessControl/selectors';
import { getCurrentStageProviderDetail, getLogin } from 'store/login/selectors';
import { getUserPlatform, PLATFORM } from 'utils';
import { getItem, removeItem, setItem } from 'utils/localStorage';
import { getIsJumpedUser, UserScopeType } from 'utils/userScopeHelper';

import styles from './externalUserLoginForm.module.scss';

const initialFieldValidation = {
  providerRelatedId: false,
  loginId: false,
  password: false,
};

type Props = {
  providerId?: string;
  process?: string;
  title: ReactNode;
  isPopUp: boolean;
  providerIdInQuery?: string;
  targetURL?: string;
  onSubmitCallback?: () => void;
};

function ExternalUserLoginForm(props: Props) {
  const dispatch = useDispatch();
  const {
    providerId,
    process,
    title,
    isPopUp,
    providerIdInQuery,
    targetURL,
    onSubmitCallback,
  } = props;
  const loginState = useSelector(getLogin);
  const location = useSelector(getLocation);
  const currentStageProviderDetail = useSelector(getCurrentStageProviderDetail);
  const [rememberProviderId, setRememberProviderId] = useState(false);
  const [pwdShow, setPwdShow] = useState<boolean>(false);
  const userScope = useSelector(getLoggedInUserScope);
  const isJumpedUser = getIsJumpedUser(userScope);

  const [hasFieldError, setHasFieldError] = useState(false);
  const [nbOfAllowedFailure, setNbOfAllowedFailure] = useState(3);
  const [errorType, setErrorType] = useState(process || '');
  const [fieldErrorValidation, setFieldErrorValidation] = useState(initialFieldValidation);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const providerIdControl = useFormControl(
    getItem('providerRelatedId') ||
      providerIdInQuery ||
      currentStageProviderDetail?.everestProviderId,
  );
  const logIdControl = useFormControl(
    getItem('loginId') || currentStageProviderDetail?.defaultLoginId || '',
  );
  const passwordControl = useFormControl(getItem('password') || '');

  const fieldOnChange = (fieldControl: ReturnData) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    fieldControl.onChange(event);
    setFieldErrorValidation(initialFieldValidation);

    setDisableSubmit(
      !(providerIdControl.currentData || logIdControl.currentData || passwordControl.currentData),
    );
  };

  const onChangeRememberProviderId = () => {
    setRememberProviderId(!rememberProviderId);
    setItem<boolean>('isRememberLogin', !rememberProviderId);
  };
  const forgotPassword = () => {
    dispatch(pushLocation(`/login/forgotPassword`));
  };

  const handlePwdShow = () => setPwdShow(prev => !prev);

  const handleOnClickReturnTop = () => {
    dispatch(jumpToV1TopFromDialog({ provider: providerId || '' }));
  };

  const handleOnClickRFBAnnounce = () => {
    window.location.href = env('UNIVERSAL_RFB_ANNOUNCE_URL') || '';
  };

  const getRedirectUrl = useCallback(
    (userScope: UserScopeType) => {
      const basename = env('UNIVERSAL_BASE_NAME') ? `/${env('UNIVERSAL_BASE_NAME')}` : '';
      const basePath = `${window.location.origin}${basename}`;
      const providerId = providerIdControl.currentData;
      const userPlatform = getUserPlatform(userScope);

      if (targetURL) {
        return `${basePath}${targetURL}`;
      }
      const url =
        userPlatform === PLATFORM.GROUP_EXTRANET
          ? `/providerGroup/${providerId}`
          : `/provider/${providerId}`;
      return `${basePath}${url}${location?.search || ''}`;
    },
    [location, providerIdControl.currentData, targetURL],
  );

  const onSubmit = useCallback(async () => {
    if (!loginState.isFetching) {
      setFieldErrorValidation({
        providerRelatedId: !providerIdControl.currentData,
        loginId: !logIdControl.currentData,
        password: !passwordControl.currentData,
      });
      const credentials = {
        providerRelatedId: providerIdControl.currentData,
        loginId: logIdControl.currentData,
        password: passwordControl.currentData,
      };

      // Get index 0 because only one error type can be displayed
      const missingField = (Object.keys(credentials) as Array<keyof typeof credentials>).filter(
        item => !credentials[item],
      )[0];
      if (missingField) {
        setHasFieldError(true);

        switch (missingField) {
          case 'providerRelatedId':
            setErrorType('providerIdRequired');
            break;
          case 'loginId':
            setErrorType('loginIdRequired');
            break;
          case 'password':
            setErrorType('passwordRequired');
            break;
          default:
            setErrorType('default');
        }
      } else {
        const response = await dispatch(postLogin(credentials));
        if (nbOfAllowedFailure > 0) {
          setNbOfAllowedFailure(nbOfAllowedFailure - 1);
        } else if (isPopUp && nbOfAllowedFailure === 0) {
          dispatch(pushLocation('/logout'));
        }
        if (response.message) {
          const message = response.message;
          setHasFieldError(true);
          setErrorType(message);

          // Additional steps for particular error messages

          if (message.includes('login.expired')) {
            dispatch(resetPasswordPreData(credentials));
          } else if (message.includes('password_expired')) {
            dispatch(resetPasswordPreData(credentials));
            dispatch(pushLocation('login/passwordExpired'));
          } else if (isPopUp && message.includes('account_locked')) {
            dispatch(pushLocation('/logout'));
          }
        } else if (response.token) {
          dispatch(setIsShowBrowserPrompt(false));
          window.location.href = getRedirectUrl(response.scope);

          if (onSubmitCallback) {
            onSubmitCallback();
          }
          if (rememberProviderId) {
            setItem<string>('providerRelatedId', providerIdControl.currentData);
            setItem<string>('loginId', logIdControl.currentData);
          } else {
            removeItem('providerRelatedId');
            removeItem('loginId');
          }
        }

        // Handles different error response from API
        else {
          setHasFieldError(true);
          setErrorType('default');
        }
      }
    }
  }, [
    dispatch,
    getRedirectUrl,
    isPopUp,
    logIdControl.currentData,
    loginState.isFetching,
    nbOfAllowedFailure,
    onSubmitCallback,
    passwordControl.currentData,
    providerIdControl.currentData,
    rememberProviderId,
  ]);

  useEffect(() => {
    setDisableSubmit(
      !(providerIdControl.currentData || logIdControl.currentData || passwordControl.currentData),
    );
    setRememberProviderId(getItem('isRememberLogin'));
  }, [
    logIdControl.currentData,
    passwordControl.currentData,
    providerIdControl.currentData,
    rememberProviderId,
  ]);

  return (
    <div>
      <p className={styles.title}>{title}</p>
      {(hasFieldError || process === 'passwordChanged') && (
        <IconText
          className={cx(
            styles.errorMessage,
            process === 'passwordChanged' ? styles.infoMessage : styles.attentionMessage,
          )}
          icon={process === 'passwordChanged' ? <Info size={24} /> : <Attention size={24} />}
          text={<ErrorMessage errorType={errorType} />}
        />
      )}
      <div className={styles.form}>
        <TextField
          className={styles.fieldContainer}
          name={'providerRelatedId'}
          data-testid={'externalUserLoginForm-providerRelatedId-input'}
          onChange={fieldOnChange(providerIdControl)}
          value={providerIdControl.currentData}
          onClear={providerIdControl.onClear}
          inputProps={{
            className: styles.inputField,
            placeholder: useTranslate({
              id: 'loginlogout.login_panel.form.provider_id_example_text',
            }),
          }}
          label={<Translate id={'loginlogout.login_panel.form.provider_id'} />}
          hasError={fieldErrorValidation.providerRelatedId}
        />
        <TextField
          className={styles.fieldContainer}
          name={'loginId'}
          data-testid={'externalUserLoginForm-loginId-input'}
          onChange={fieldOnChange(logIdControl)}
          value={logIdControl.currentData}
          onClear={logIdControl.onClear}
          inputProps={{
            placeholder: useTranslate({ id: 'loginlogout.login_panel.form.login_id_example_text' }),
          }}
          label={<Translate id={'loginlogout.login_panel.form.login_id'} />}
          hasError={fieldErrorValidation.loginId}
        />
        <TextField
          className={styles.fieldContainer}
          name={'password'}
          data-testid={'externalUserLoginForm-password-input'}
          onChange={fieldOnChange(passwordControl)}
          value={passwordControl.currentData}
          inputProps={{
            placeholder: useTranslate({ id: 'loginlogout.login_panel.form.password_example_text' }),
            type: `${pwdShow ? 'text' : 'password'}`,
          }}
          trailingIcon={
            <button className={styles.toggleBtn} type="button" onClick={handlePwdShow}>
              {pwdShow ? (
                <EyeOpen size={24} className={styles.eyeOpen} data-testid="eyeOpen-icon" />
              ) : (
                <EyeClose size={24} data-testid="eyeClose-icon" />
              )}
            </button>
          }
          label={
            <div className={styles.passwordContainer}>
              <Translate id={'loginlogout.login_panel.form.password'} />
              <span className={cx(styles.forgotPassword, styles.textLink)} onClick={forgotPassword}>
                <Translate id={'loginlogout.login_panel.button.forget_your_password'} />
              </span>
            </div>
          }
          hasError={fieldErrorValidation.password}
        />
        <div className={styles.buttonGroup}>
          <Checkbox
            className={styles.rememberId}
            name="rememberId"
            isChecked={rememberProviderId}
            onChange={onChangeRememberProviderId}
            label={<Translate id={'loginlogout.login_panel.form.remember_id'} />}
          />
          <FlatButton
            onClick={onSubmit}
            className={styles.loginBtn}
            classType="primary"
            data-testid={'externalUserLoginForm-login-button'}
            isLoading={loginState.isFetching}
            isDisabled={disableSubmit}
          >
            <Translate
              className={styles.loginBtnLabel}
              id={'loginlogout.login_panel.button.log_in'}
            />
          </FlatButton>
          <IconTextLink
            className={cx(styles.announceLink)}
            data-testid="externalUserLoginForm-RFBAnnounceLink"
            text={<Translate id="loginlogout.link.announcement_page_url" />}
            onClick={handleOnClickRFBAnnounce}
          />
          {isJumpedUser && (
            <IconTextLink
              className={cx(styles.returnLink)}
              data-testid="externalUserLoginForm-returnV1Link"
              text={
                <Translate id="loginlogout.login_panel.button.return_to_room_setting_and_reservation_management_top_page" />
              }
              onClick={handleOnClickReturnTop}
            />
          )}
        </div>
      </div>
    </div>
  );
}
ExternalUserLoginForm.defaultProps = {
  process: '',
  successPath: '',
  providerIdInQuery: '',
};

export default ExternalUserLoginForm;

import React, { ReactNode } from 'react';
import { Checkmark } from '@travel/icons/ui';
import cx from '../../utils/classnames';
import styles from './progressIndicator.module.scss';

type Props = {
  /** style name to control appearance of this component from parent component*/
  className: string;
  /** progress indicator label*/
  label: Array<ReactNode>;
  /** active progress indicator at 1 as first number*/
  activeStep: number;
};

export default function ProgressIndicator(props: Props) {
  const { className, label, activeStep, ...rest } = props;

  const stepperDataLength = label && label.length;

  return (
    <>
      {stepperDataLength && (
        <ul className={cx(className, styles.flexCenter)} {...rest}>
          {label.map((each, index) => {
            const stepNumber = index + 1;
            const isActiveStep = stepNumber === activeStep;

            return (
              each && (
                <li key={`${each}${index}`}>
                  <div className={cx(styles.flexCenter)}>
                    <span className={cx(styles.connector, index === 0 && styles.invisible)} />
                    <span className={cx(styles.step, isActiveStep && styles.active)}>
                      {!isActiveStep && stepNumber < activeStep ? (
                        <Checkmark size={16} />
                      ) : (
                        stepNumber
                      )}
                    </span>
                    <span
                      className={cx(
                        styles.connector,
                        stepperDataLength === stepNumber && styles.invisible,
                      )}
                    />
                  </div>
                  <div className={cx(styles.stepName, isActiveStep && styles.activeStep)}>
                    {each}
                  </div>
                </li>
              )
            );
          })}
        </ul>
      )}
    </>
  );
}

ProgressIndicator.defaultProps = {
  className: '',
  label: ['Enter your details', 'Final confirmation', 'Booking confirmed'],
  activeStep: 1,
};

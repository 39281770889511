import React from 'react';
import { Translate } from '@travel/translation';
import FAQLink from 'components/FAQLink';

export const labels = {
  oneTimeKeyHeader: <Translate id={'loginlogout.forget_password_one_time_key.header.title'} />,
  oneTimeKeyInstruction: (
    <Translate id={'loginlogout.forget_password_one_time_key.Instruction_text'} />
  ),
  attentionTitle: (
    <Translate id={'loginlogout.forget_password_one_time_key.Instruction_text.attention_title'} />
  ),
  attentionDescription: (
    <Translate id={'loginlogout.forget_password_one_time_key.Instruction_text.attention_message'} />
  ),
  oneTimeKeyLabel: <Translate id={'loginlogout.forget_password_one_time_key.form.one_time_key'} />,
  cancelButton: <Translate id={'loginlogout.forget_password_one_time_key.button.cancel'} />,
  submitButton: <Translate id={'loginlogout.forget_password_one_time_key.button.submit'} />,
  resetPasswordInstruction: (
    <Translate
      id={'loginlogout.forget_password_reset_password.Instruction_text'}
      data={{ password_rules_link: <FAQLink isPwd={true} /> }}
    />
  ),
} as const;

export const labelIds = {
  oneTimeKeyLabel: 'loginlogout.forget_password_one_time_key.form.one_time_key',
};

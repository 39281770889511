import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconText, ProgressIndicator } from '@travel/ui';
import { Attention } from '@travel/icons/ui';
import { pushLocation } from 'store/__router/actions';
import { processChangeExpiredPassword } from 'store/login/actions';
import { getLogin } from 'store/login/selectors';
import { NewPasswordData } from 'Login-Types';
import ErrorMessage from 'pages/Login/errorMessage';
import NewPasswordForm from '../NewPasswordForm';
import { Translate } from '@travel/translation';
import {
  PASSWORD_REGEXES,
  ONE_CAPITAL,
  ONE_LOWERCASE,
  ONE_NUMBER,
  ONE_SYMBOL,
  NOT_CONSECUTIVE_NUM,
  NOT_THREETIMES_ALPHA,
  NOT_INVALID_WORDS,
} from 'constants/validations';
import styles from './resetPassword.module.scss';
import FAQLink from 'components/FAQLink';
import { MAP_RESPONSE_TO_CODE } from 'constants/login';

export const passwordExpiredSteps = [
  <Translate id={'loginlogout.forget_password_reset_password.status_bar.information'} />,
  <Translate id={'loginlogout.forget_password_reset_password.status_bar.reset_password'} />,
];

function ResetPassword() {
  const dispatch = useDispatch();
  const { isFetching, passwordPreData } = useSelector(getLogin);

  const [errorType, setErrorType] = useState('');
  const resetErrorType = () => setErrorType('');
  const [isSuccessResetPassword, setIsSuccessResetPassword] = useState(false);

  useEffect(() => {
    if (!passwordPreData.providerRelatedId) {
      dispatch(pushLocation(`/login`));
      window.location.reload();
    }
  });

  const onResetPassword = useCallback(
    async (passwordData: NewPasswordData) => {
      if (isFetching) return;

      const newPassword = passwordData?.newPassword || '';
      const isValidPassword = PASSWORD_REGEXES.reduce(
        (acc, curRegEx) => acc && curRegEx.test(newPassword),
        true,
      );

      if (!ONE_CAPITAL.test(newPassword)) {
        setErrorType('no_capital');
        return;
      }
      if (!ONE_LOWERCASE.test(newPassword)) {
        setErrorType('no_lowercase');
        return;
      }

      if (!ONE_NUMBER.test(newPassword)) {
        setErrorType('no_number');
        return;
      }
      if (!ONE_SYMBOL.test(newPassword)) {
        setErrorType('no_symbol');
        return;
      }
      if (!NOT_CONSECUTIVE_NUM.test(newPassword)) {
        setErrorType('consecutive_number');
        return;
      }
      if (!NOT_THREETIMES_ALPHA.test(newPassword)) {
        setErrorType('three_times_alpha');
        return;
      }

      if (newPassword.length < 8) {
        setErrorType('too_short');
        return;
      }

      if (newPassword.length > 64) {
        setErrorType('too_long');
        return;
      }

      if (!NOT_INVALID_WORDS.test(newPassword)) {
        setErrorType('invalid_word');
        return;
      }

      if (!isValidPassword) {
        setErrorType('invalid_password');
        return;
      }
      if (newPassword !== passwordData.passwordConfirm) {
        setErrorType('passwordMismatch');
        return;
      }
      const credentialsData = {
        providerRelatedId: passwordPreData.providerRelatedId,
        loginId: passwordPreData.loginId,
        password: passwordPreData.password,
        newPassword,
      };
      const response = await dispatch(processChangeExpiredPassword(credentialsData));

      if (typeof response === 'boolean' && response) {
        setIsSuccessResetPassword(true);
        setErrorType('');
      } else if (response.code && response.code.includes('newPW.usedBefore')) {
        setErrorType('usedPassword');
      } else if (response.code && response.code.includes('error.invalidPw')) {
        setErrorType(MAP_RESPONSE_TO_CODE[response.code]);
      } else {
        setErrorType('unexpected_error');
      }
    },
    [
      dispatch,
      isFetching,
      passwordPreData.loginId,
      passwordPreData.password,
      passwordPreData.providerRelatedId,
    ],
  );

  return (
    <div
      className={styles.wrapper}
      data-locator-id="ResetPassword-a84d1d3d-f2eb-4abf-a4c5-7f7a592d0aae"
      data-testid="resetPassword-wrapper"
    >
      <div className={styles.headerContainer}>
        <p className={styles.headerTitle}>
          <Translate id={'loginlogout.forget_password_information.header.title'} />
        </p>
        <ProgressIndicator
          className={styles.progressIndicator}
          label={passwordExpiredSteps}
          activeStep={2}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentTitle}>
          <p>
            <Translate
              id={'loginlogout.forget_password_reset_password.Instruction_text'}
              data={{ password_rules_link: <FAQLink isPwd={true} /> }}
            />
          </p>
        </div>
        {errorType && (
          <IconText
            className={styles.errorMessage}
            icon={<Attention size={24} />}
            text={<ErrorMessage errorType={errorType} />}
          />
        )}
        <NewPasswordForm
          onResetPassword={onResetPassword}
          isFetching={isFetching}
          isSuccessPasswordChanged={isSuccessResetPassword}
          errorType={errorType}
          resetErrorType={resetErrorType}
        />
      </div>
    </div>
  );
}

export default ResetPassword;

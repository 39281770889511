import React from 'react';
import { Translate } from '@travel/translation';
import { labels } from './labels';

type Props = {
  errorType: string;
};

const translationStatus: { [key: string]: string } = {
  passwordRequired: 'password_required',
  passwordChanged: 'new_password_required',
  password_expired: 'password_expired',
  wrong_password: 'wrong_password',
  wrong_id: 'wrong_id',
  loginIdRequired: 'login_id_required',
  invalidEmail: 'invalid_email',
  invalidOneTimeKey: 'invalid_key',
  invalid_key: 'invalid_key',
  usedPassword: 'password_recent',
  problem: 'problem',
  wrong_password_last_time: 'wrong_password_last_time',
  account_locked: 'account_locked',
  unexpected_error: 'unexpected_error',
};

function ErrorMessage(props: Props) {
  return (
    <>
      {(() => {
        if (props.errorType === 'passwordMismatch') {
          return labels.pwdError.mismatch;
        } else if (props.errorType === 'invalid_password') {
          return labels.pwdError.invalidChar;
        } else if (props.errorType === 'no_capital') {
          return labels.pwdError.noCapital;
        } else if (props.errorType === 'no_lowercase') {
          return labels.pwdError.noLowercase;
        } else if (props.errorType === 'no_number') {
          return labels.pwdError.noNumber;
        } else if (props.errorType === 'no_symbol') {
          return labels.pwdError.noSymbol;
        } else if (props.errorType === 'consecutive_number') {
          return labels.pwdError.consecutiveNumber;
        } else if (props.errorType === 'three_times_alpha') {
          return labels.pwdError.threeTimesAlpha;
        } else if (props.errorType === 'contain_username') {
          return labels.pwdError.containUsername;
        } else if (props.errorType === 'invalid_word') {
          return labels.pwdError.invalidWord;
        } else if (props.errorType === 'too_long') {
          return labels.pwdError.tooLong;
        } else if (props.errorType === 'too_short') {
          return labels.pwdError.tooShort;
        } else if (props.errorType === 'invalid_key') {
          return labels.keyError.invalidKey;
        } else {
          return (
            <p>
              <Translate
                id="loginlogout.login_panel.system_message"
                condition={() =>
                  `status == ${translationStatus[props.errorType] || translationStatus.problem}`
                }
              />
            </p>
          );
        }
      })()}
    </>
  );
}

export default ErrorMessage;

import React from 'react';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import OneTimeKey from './components/OneTimeKey';
import ResetPassword from './components/ResetPassword';
import styles from './login.module.scss';

type Props = {
  params: {
    process?: string;
  };
  query: {
    providerRelatedId?: string;
    targetURL?: string;
  };
  state?: {
    providerUuid: string;
    userId: string;
    email: string;
  };
};

function Login(props: Props) {
  const { params, query, state } = props;

  return (
    <div className={styles.wrapper} data-locator-id="Login-5c1b68e6-0fd1-4a03-9b14-a9fab665b4f4">
      {(() => {
        switch (params.process) {
          case 'forgotPassword':
            return <ForgotPassword credentials={state} />;
          case 'oneTimeKey':
            return <OneTimeKey />;
          case 'passwordExpired':
            return <ResetPassword />;
          default:
            return (
              <LoginForm
                process={params.process}
                providerIdInQuery={query?.providerRelatedId}
                targetURL={query?.targetURL}
              />
            );
        }
      })()}
    </div>
  );
}

export default Login;

export const MAP_RESPONSE_TO_CODE: { [key: string]: string } = {
  'error.invalidPw.TOO_SHORT': 'too_short',
  'error.invalidPw.TOO_LONG': 'too_long',
  'error.invalidPw.INSUFFICIENT_UPPERCASE': 'no_capital',
  'error.invalidPw.INSUFFICIENT_LOWERCASE': 'no_lowercase',
  'error.invalidPw.INSUFFICIENT_DIGIT': 'no_number',
  'error.invalidPw.INSUFFICIENT_SPECIAL': 'no_symbol',
  'error.invalidPw.ALLOWED_MATCH': 'invalid_password',
  'error.invalidPw.ILLEGAL_USERNAME': 'contain_username',
  'error.invalidPw.ILLEGAL_MATCH': 'three_times_alpha',
  'error.invalidPw.ILLEGAL_NUMERICAL_SEQUENCE': 'consecutive_number',
  'error.invalidPw.ILLEGAL_WORD': 'invalid_word',
};

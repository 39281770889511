import React from 'react';
import { Translate } from '@travel/translation';
import { IconTextLink } from '@travel/ui';

type Props = {
  isPwd?: boolean;
};

function FAQLink(props: Props) {
  const handleLinkClick = () => {
    window.open(
      props.isPwd
        ? 'https://travel.rakuten.co.jp/B2B/manual/password_E/index.html#ans3'
        : 'https://travel.rakuten.co.jp/B2B/manual/password_E/index.html',
      '_blank',
    );
  };
  return (
    <IconTextLink
      data-testid="faqLink-link"
      text={<Translate id="loginlogout.link.text" />}
      onClick={handleLinkClick}
    />
  );
}

export default FAQLink;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Translate } from '@travel/translation';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import { logout } from 'store/login/actions';
import { makePublicImagePath } from 'utils/urlHelper';

import { getNormalizedCookies, getVerifyTokenDetail } from 'store/login/selectors';
import { COOKIE_NAMES } from 'utils';
import { getIsRFBUser } from 'utils/verifyTokenHelper';
import ExternalUserLoginForm from '../ExternalUserLoginForm';
import styles from './loginForm.module.scss';

type Props = {
  process?: string;
  providerIdInQuery?: string;
  targetURL?: string;
};

const AUTO_ANIMATED_BACKGROUND_TIMEOUT = 10000;

function LoginForm(props: Props) {
  const dispatch = useDispatch();
  const { process, providerIdInQuery, targetURL } = props;

  const SSOLoginOnClick = () => {
    dispatch(logout({ redirectToSSO: true }));
  };

  // background animation
  const isAutoAnimated = useRef<boolean>(true);
  const autoAnimatedTimerRef = useRef<number>();
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const BACKGROUND_IMAGE_PATHS = ['image-login-0.jpg', 'image-login-1.jpg', 'image-login-2.jpg'];

  const normalizedCookies = useSelector(getNormalizedCookies);
  const everestToken = normalizedCookies && normalizedCookies[COOKIE_NAMES.API_TOKEN_NAME]?.value;
  const tokenInfo = useSelector(getVerifyTokenDetail);
  const isRFBUser = getIsRFBUser(tokenInfo.loginScheme);

  useEffect(() => {
    clearTimeout(autoAnimatedTimerRef.current);
    autoAnimatedTimerRef.current = window.setTimeout(() => {
      isAutoAnimated.current = true;
      setBackgroundIndex(prevIndex =>
        prevIndex === BACKGROUND_IMAGE_PATHS.length - 1 ? 0 : prevIndex + 1,
      );
    }, AUTO_ANIMATED_BACKGROUND_TIMEOUT);

    return () => {
      clearTimeout(autoAnimatedTimerRef.current);
    };
  }, [BACKGROUND_IMAGE_PATHS.length, backgroundIndex]);

  return (
    <div className={styles.wrapper} data-testid="loginForm-wrapper-div">
      {/* NOTE: we render all images to avoid image blinking */}
      <img
        className={cx(
          styles.imageBackground,
          backgroundIndex === 0 && styles.active,
          isAutoAnimated.current && styles.auto,
        )}
        src={makePublicImagePath(BACKGROUND_IMAGE_PATHS[0])}
        alt="background-1"
      />
      <img
        className={cx(
          styles.imageBackground,
          backgroundIndex === 1 && styles.active,
          isAutoAnimated.current && styles.auto,
        )}
        src={makePublicImagePath(BACKGROUND_IMAGE_PATHS[1])}
        alt="background-2"
      />
      <img
        className={cx(
          styles.imageBackground,
          backgroundIndex === 2 && styles.active,
          isAutoAnimated.current && styles.auto,
        )}
        src={makePublicImagePath(BACKGROUND_IMAGE_PATHS[2])}
        alt="background-3"
      />

      <div className={styles.messageContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.mainTitle}>
            <Translate id="loginlogout.top_page.message.greeting" />
          </h1>
          <h1 className={styles.subTitle}>
            <Translate id="loginlogout.top_page.message.title" />
          </h1>
          <h2>
            <Translate id="loginlogout.top_page.message.body" />
          </h2>
          <div className={styles.imageButtonsWrapper}>
            {BACKGROUND_IMAGE_PATHS.map((_, index) => (
              <button
                key={index}
                className={cx(styles.imageButton, backgroundIndex === index && styles.active)}
                onClick={() => {
                  isAutoAnimated.current = false;
                  setBackgroundIndex(index);
                }}
              ></button>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.flexContainer}>
        {!everestToken && !isRFBUser && (
          <div className={styles.container} data-testid="loginForm-externalUserLoginForm-container">
            <ExternalUserLoginForm
              process={process}
              title={<Translate id={'loginlogout.login_panel.header.title'} />}
              targetURL={targetURL}
              isPopUp={false}
              providerIdInQuery={providerIdInQuery}
            />
            <div className={styles.ssoContainer}>
              <p className={cx(styles.fontMars, styles.label)}>
                <Translate id={'loginlogout.login_panel.message.intra_account_login'} />
              </p>
              <FlatButton
                className={styles.ssoBtn}
                onClick={SSOLoginOnClick}
                data-testid="sso-login-button"
              >
                <Translate id={'loginlogout.login_panel.button.sso_login'} />
              </FlatButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

LoginForm.defaultProps = {
  process: '',
  providerIdInQuery: '',
};

export default LoginForm;
